@import './reset.css';
@import './fonts.scss';

html {
	min-height: 100%;
	height: 100%;
}

body {
	height: 100%;
	margin: 0;
	font-family: "MTSSans", "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
		"Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body.body-hidden {
	overflow: hidden;
}
div#root {
	height: 100%;
	overflow: hidden;
}

input,
textarea,
button {
	font-family: inherit;
}

span[data-mui-internal-clone-element] {
	line-height: 0;
}

.MuiFormControlLabel-labelPlacementEnd {
	margin-right: 0 !important;
}

.Toastify__toast {
	margin-bottom: 0 !important;
	padding: 0 !important;
	min-height: 0 !important;
	border-radius: 12px !important;

	&-container {
		padding: 0 !important;
		width: auto !important;
	}

	&-body {
		cursor: default;
		font-family: 'MTSSans', sans-serif;
		font-weight: 400;
		font-size: 16px;
	}
}

code {
	font-family: "MTSSans", "Courier New", monospace;
}